import React from "react"
import { Helmet } from "react-helmet"

function Seo({ metaTitle, metaDescription, description, title, image, url, type, locale }) {
  return (
    <Helmet
      title={metaTitle}
      htmlAttributes={{ lang: locale }}
      meta={[
        { name: "description", content: metaDescription },
        { property: "og:title", content: title },
        { property: "og:description", content: description },
        { property: "og:type", content: type },
        { property: "og:image", content: image },
        { property: "og:url", content: url },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: metaDescription }
      ]}
      link={[
        {"rel": "icon",
         "type": "image/png",
         "href": "https://guararapes-meuambiente.s3.sa-east-1.amazonaws.com/favicon.ico"
        }
       ]}
    />
  )
}

export default Seo
